import Slider from 'react-slick';
import styled from 'styled-components';
import LeftArrow from '/public/svgs/left-arrow-sl.svg';
import RightArrow from '/public/svgs/right-arrow-sl.svg';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FEATURED_WORKS } from 'gql/works';
import { Img, SixthTitle, ThirdParagraph, Truncated } from '../StyledComponentsLib/StyledComponentsLib';
import Link from 'next/link';
import { Category } from 'generated-graphql';
import { get } from 'lodash';
import ResourceItem from '@/components/ResourceItems/ResourceItem';
const SliderContainer = styled.div`
  padding-bottom: 80px;
  position: relative;
  a,
  p {
    color: #000;
  }

  @media all and (min-width: 1200px) {
    width: 1300px;
  }
  @media all and (min-width: 1400px) {
    width: 1500px;
  }
  @media all and (min-width: 1500px) {
    width: 1600px;
  }
  @media all and (min-width: 1700px) {
    width: 2000px;
  }
  @media all and (min-width: 2300px) {
    width: 2500px;
  }
  .slick-list {
    padding: 0px 0px !important;
    .slick-track {
      display: flex;
      padding: 8px 0px;
      .slick-slide {
        width: 100% !important;
        img {
          width: 100%;
        }
      }
    }
  }
  .prev-arrow,
  .next-arrow {
    top: auto;
    bottom: -40px;
    left: 10px;
    z-index: 2;
    position: absolute;
    cursor: pointer;
    &:nth-child(3) {
      left: 90px;
    }
    &:hover {
      path {
        stroke: #000;
      }
    }
  }
`;

const SectionSlider = ({
  items,
  title,
  exclude,
  isSeries,
}: {
  items: any;
  title?: string;
  exclude?: string;
  isSeries?: string;
}) => {
  const [getWorks] = useLazyQuery(FEATURED_WORKS);
  const [sliderItems, setSliderItems] = useState(items);

  const isWorkPage = !items

  useEffect(() => {
    if (isWorkPage) {
      getWorks({
        onCompleted: (data) => setSliderItems(data.works.nodes),
      });
    }
  }, []);

  const CustomPrevArrow = (props: any) => (
    <LeftArrow {...props} className="prev-arrow" />
  );

  const CustomNextArrow = (props: any) => (
    <RightArrow {...props} className="next-arrow" />
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 3000,
    slidesToShow: sliderItems?.length > 5 ? 5 : items?.length,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          initialSlide: 0,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          initialSlide: 0,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  let content = <SliderContainer>
    <Slider {...settings}>
      {sliderItems?.map(
        (item: any) => {
          if (item.slug !== exclude) {
            const imgSrc = get(item, 'featuredImage.node.mediaDetails.sizes.0.sourceUrl', get(item, 'featuredImage.node.sourceUrl', ''));

            return <div className='px-3' key={item.id}>
              <ResourceItem item={item} />
            </div>
          }
        },
      )}
    </Slider>
  </SliderContainer>;

  if (sliderItems?.length <= 3) {
    content = <div className='row'>
      {sliderItems?.map(
        (item: any) => {
          if (item.slug !== exclude) {
            const imgSrc = get(item, 'featuredImage.node.mediaDetails.sizes.0.sourceUrl', get(item, 'featuredImage.node.sourceUrl', ''));

            return <div className='col-md-4 px-3' key={item.id}>
              <ResourceItem item={item} />
            </div>
          }
        },
      )}
    </div>;
  }
  return (
    <div className="section">
      <div className="container">
        {title && <h4 style={{ marginBottom: 32 }}>{title}</h4>}

        {content}
      </div>
    </div>
  );
};

export default SectionSlider;
